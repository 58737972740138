import convertStringToNumber from "@/utils/convertStringToNumber";
import { InsuranceRequestInterface } from "@/models/order/InsuranceRequest";
import { InsuranceResponseInterface } from "@/models/order/InsuranceResponse";
import { insuranceCalculation } from "@/api/insurance";
import { computed, ref } from "vue";
import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { generateId } from "@/utils/generateId";

export function useInsuranceCalc(order: OrderItemInterface) {
  const insReqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
  const insResMessage = ref<string>("");
  const insReqLoading = computed(
    () => insReqStatus.value === RequestStatusEnum.Loading
  );
  const insurancePremium = ref<string>("");
  const orderGuid = ref<string>("");
  const insuranceInfo = ref<InsuranceResponseInterface | null>(null);
  const withInsurance = ref<boolean>(false);

  const insCalc = async () => {
    insReqStatus.value = RequestStatusEnum.Loading;
    insResMessage.value = "";

    const loadingAddress = order.places[0].loadingAddresses[0].address.returnAddress();
    const unloadingAddress = order.places[0].loadingAddresses[0].unloadingAddresses[0].address.returnAddress();

    const req = {
      uid: !orderGuid.value ? generateId() : orderGuid.value,
      date: new Date().toISOString(),
      refrigeratorRisk: false,
      departureKLADR: loadingAddress.kladrRegion,
      arrivalKLADR: unloadingAddress.kladrRegion,
      declaredValueInKops: convertStringToNumber(order.assessedValue) * 100,
      insurance: [
        {
          insuranceType: "cargo",
          insuranceSum: convertStringToNumber(order.assessedValue) * 100
        }
      ]
    } as InsuranceRequestInterface;

    try {
      const res = await insuranceCalculation(req);

      if (!res.isSuccess) {
        insReqStatus.value = RequestStatusEnum.Error;
        insResMessage.value = `Запрос стоимости страхования. ${res.message}`;
        return;
      }

      insReqStatus.value = RequestStatusEnum.Success;
      insResMessage.value = "Успех!";
      insurancePremium.value = res.entity.insurance[0].insurancePrice.toString();
      insuranceInfo.value = res.entity;
    } catch (e) {
      insReqStatus.value = RequestStatusEnum.Error;
      insResMessage.value = "Ошибка сервиса!";
    }
  };

  return {
    insReqLoading,
    insurancePremium,
    orderGuid,
    insuranceInfo,
    withInsurance,
    insCalc
  };
}
