import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import LoadingAddressObjectModel from "@/models/order/LoadingAddressObject.model";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import DimensionsFormModel from "@/models/order/DimensionsForm.model";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";
import { PlaceOptionsModel } from "@/models/order/PlaceOptions.model";

export default class PlaceItemModel implements PlaceItemInterface {
  count: number;
  tax: string;
  name: string;
  barcode: string;
  comment: string;
  assessedValue: string;
  deliveryPrice: string;
  dimensions: DimensionsFormInterface;
  loadingAddresses: LoadingAddressObjectInterface[];
  options: OrderOptionsItemInterface;

  constructor() {
    this.count = 0;
    this.barcode = "";
    this.name = "";
    this.loadingAddresses = [new LoadingAddressObjectModel()];
    this.dimensions = new DimensionsFormModel();
    this.assessedValue = "";
    this.deliveryPrice = "";
    this.tax = "";
    this.comment = "";
    this.options = new PlaceOptionsModel();
  }

  addLoadingAddress() {
    this.loadingAddresses.push(new LoadingAddressObjectModel());
  }
  removeLoadingAddress(loadingAddressIdx: number) {
    if (this.loadingAddresses.length <= 1) return;

    this.loadingAddresses.splice(loadingAddressIdx, 1);
  }
}
