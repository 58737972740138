import { YandexMapAddressItemDataModel } from "@/models/yandex-map/YandexMapAddressItemData.model";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { YandexMapAddressItemDataInterface } from "@/models/yandex-map/YandexMapAddressItemData.interface";
import { AddressInterface } from "@/models/order/Address.interface";

export class YandexMapAddressItemModel
  implements YandexMapAddressItemInterface {
  data: YandexMapAddressItemDataInterface;
  kind: string;
  precision: string;
  value: string;
  hasValidUnloadingAddress: () => boolean;

  constructor(address?: YandexMapAddressItemInterface) {
    this.kind = "";
    this.precision = "";
    this.value = "";
    this.data = new YandexMapAddressItemDataModel();
    this.hasValidUnloadingAddress = () => {
      if (address == null) return true;

      const unloadingAddress = this.value;
      const loadingAddress = address.value;

      return unloadingAddress != loadingAddress;
    };
  }

  returnAddress(): AddressInterface {
    const addressForm: AddressInterface = {
      postalCode: "",
      fullAddress: "",
      country: "",
      geoId: 0,
      apartment: "",
      building: "",
      house: "",
      housing: "",
      locality: "",
      region: "",
      street: "",
      kladr: "",
      kladrRegion: "",
      latitude: "",
      longitude: ""
    };

    if (this.data === null) return addressForm;

    const { data: addressData } = this;

    addressForm.country = addressData.country;
    addressForm.region = addressData.province || addressData.area;
    addressForm.locality = addressData.locality || addressData.area;
    addressForm.street = addressData.street;
    addressForm.house = addressData.house;
    addressForm.fullAddress = addressData.fullAddress;
    addressForm.latitude = addressData.latitude;
    addressForm.longitude = addressData.longitude;

    return addressForm;
  }
}
