import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import AddressObjectModel from "@/models/order/AddressObject.model";
import { LoaderItemInterface } from "@/models/person/LoaderItem.interface";
import { LoaderItemModel } from "@/models/person/LoaderItem.model";
import { LoadingOwnerItemInterface } from "@/models/person/LoadingOwnerItem.interface";
import { LoadingOwnerItemModel } from "@/models/person/LoadingOwnerItem.model";

export default class LoadingAddressObjectModel extends AddressObjectModel
  implements LoadingAddressObjectInterface {
  unloadingAddresses: AddressObjectInterface[];
  loader: LoaderItemInterface;
  loadingOwner: LoadingOwnerItemInterface;

  constructor() {
    super();
    this.unloadingAddresses = [
      new AddressObjectModel(this.datePeriod, this.address)
    ];
    this.loader = new LoaderItemModel();
    this.loadingOwner = new LoadingOwnerItemModel();
  }

  addUnloadingAddress() {
    this.unloadingAddresses.push(
      new AddressObjectModel(this.datePeriod, this.address)
    );
  }

  removeUnloadingAddress(unloadingAddressIdx: number) {
    this.unloadingAddresses.splice(unloadingAddressIdx, 1);
  }
}
