import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { parseDateString } from "@/utils/dateUtils";
import { da } from "date-fns/locale";

export default class PeriodItemModel implements PeriodItemInterface {
  dateFrom: string;
  dateTo: string;
  hourFrom: string;
  hourTo: string;
  isInterval: boolean;

  hasValidUnloadingDate: () => boolean;

  constructor(datePeriod?: PeriodItemInterface) {
    this.dateFrom = "";
    this.dateTo = "";
    this.hourFrom = "";
    this.hourTo = "";
    this.isInterval = false;

    this.hasValidUnloadingDate = () => {
      if (datePeriod == null) return true;

      const valueAsDateFrom = parseDateString(this.dateFrom);
      const loadingDate = parseDateString(
        datePeriod.isInterval ? datePeriod.dateTo : datePeriod.dateFrom
      );

      if (valueAsDateFrom == null || loadingDate == null) return true;

      return valueAsDateFrom >= loadingDate;
    };
  }
}
